import React from "react"
import { PageWrapper } from "~components/Core"
import FooterFour from "~sections/Blog/Footer/FooterSection"

const header = {
  headerClasses:
    "site-header site-header--menu-end light-header site-header--sticky",
  containerFluid: false,
  // buttonBlock : <HeaderButton className="d-none d-sm-flex" children="SignFree SEO Analysis"/>,
}

export default function HomeApp() {
  return (
    <PageWrapper headerConfig={header}>
      <div style={{ padding: "2rem" }}>
        <h1 style={{ marginTop: "10rem" }}>Request account deletion</h1>
        <p>
            If you'd like to delete your LunaJournal account. Please email brad@pyrestudios.com with the subject "Luna Journal Account Deletion Request". Within 30 days, you will recieve follow up correspondance to clarify your account information (if not provided in the original email) and your account will be closed shortly after.
        </p>
      </div>
      <FooterFour />
    </PageWrapper>
  )
}
